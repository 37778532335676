import ErrorFormatter from 'gatewayjs-common/src/ErrorFormatter';
import SilentFailure from './SilentFailure';

export default class ConfigParser {
  static async getServiceConfigurationPromise(configPromise, service) {
    return configPromise
      .then(undefinedOrNot => {
        SilentFailure.throwSilentFailureIfUndefined(undefinedOrNot);
        return undefinedOrNot;
      })
      .then(serviceSlices => {
        return serviceSlices.filter(serviceSlice => serviceSlice.name === service);
      })
      .then(serviceSlices => {
        if (serviceSlices.length === 0) {
          const error = new Error(
            `${service} is inactive on your account. Contact support to activate the payment gateway's ${service} service.`,
          );
          throw ErrorFormatter.formatError(error, null);
        }

        if (serviceSlices.length > 1) {
          const error = new Error(`Multiple ${service} services loaded. Please contact support.`);
          throw ErrorFormatter.formatError(error, null, 'gatewayError');
        }

        return serviceSlices.pop();
      })
      .then(serviceSlice => {
        switch (serviceSlice.status) {
          case 'active':
            break;
          case 'hidden':
          case 'inactive':
          default:
            throw ErrorFormatter.formatError(
              new Error(
                `${service} is inactive on your account. Contact support to activate the payment gateway's ${service} service.`,
              ),
              null,
            );
        }

        return serviceSlice;
      });
  }
}
