/**
 * Keeps track of which UI is mounted and attached to the DOM. Only one of these should ever exist.
 */
class ThreeDSecureMountedUISingleton {
  constructor() {
    this.mountedUI = null;
  }

  static create() {
    if (window.GatewayJSThreeDSecureMountedUISingleton instanceof ThreeDSecureMountedUISingleton) {
      return window.GatewayJSThreeDSecureMountedUISingleton;
    }

    const singleton = new ThreeDSecureMountedUISingleton();

    window.GatewayJSThreeDSecureMountedUISingleton = singleton;

    return singleton;
  }

  isThisUiAlreadyMounted(threeDSecureUI) {
    return this.mountedUI?.id !== undefined && this.mountedUI?.id === threeDSecureUI?.id;
  }

  isAnotherUiAlreadyMounted(threeDSecureUI) {
    return this.mountedUI?.id !== undefined && this.mountedUI?.id !== threeDSecureUI?.id;
  }

  isUiRegisteredThatWasDetached() {
    return this.mountedUI?.isMounted() && !document.documentElement.contains(this.mountedUI.iframe);
  }

  register(threeDSecureUI) {
    this.mountedUI = threeDSecureUI;
  }

  unregister() {
    this.mountedUI = null;
  }
}

export default ThreeDSecureMountedUISingleton;
