import Eventable from 'gatewayjs-common/src/Eventable';
import ThreeDSecureMountedUISingleton from './ThreeDSecureMountedUISingleton';

const supportedEvents = [
  '_gatewayJsInternal_error',
  'failure',
  'challenge',
  'complete',
  '_resize',
  'error',
  '_debug',
];

export default class ThreeDSecureUI extends Eventable {
  constructor(params, threeDSPromise, publicKey, id, cartCorrelationId) {
    super();
    this.params = params;
    this.threeDSPromise = threeDSPromise;
    this.publicKey = publicKey;
    this.cartCorrelationId = cartCorrelationId;
    this.id = id;
    this.iframe = null;
  }

  static create(options) {
    const { threeDSPromise, publicKey, id, cartCorrelationId } = options;
    const newOptions = options;
    delete newOptions.threeDSPromise;
    delete newOptions.publicKey;
    delete newOptions.id;
    delete newOptions.cartCorrelationId;

    const frame = new ThreeDSecureUI(newOptions, threeDSPromise, publicKey, id, cartCorrelationId);

    frame.on('_resize', e => {
      frame.resize(e.height, e.width);
    });

    frame.on('_gatewayJsInternal_error', e => {
      frame.invokeCallbacks('error', {
        ...e,
        error: new Error(`${e.message}${e.refId === null ? '' : ` REFID: ${e.refId}`}`),
      });
    });

    frame.on('error', e => {
      // eslint-disable-next-line no-console
      console.error(`Gateway.js - ThreeDSecureUI: ${e.error.message}`);
      frame.invokeCallbacks('_debug', e);
    });

    frame.on('_debug', e => {
      // eslint-disable-next-line no-console
      console.debug(e);
    });

    return frame;
  }

  on(eventName, callback) {
    this.throwErrorIfInvalidEventName(eventName, supportedEvents, 'ThreeDSecureUI');
    super.on(eventName, callback);
  }

  resize(height, width) {
    if (!(this.iframe instanceof window.HTMLIFrameElement)) {
      this.invokeCallbacks(
        'error',
        new Error('Cannot resize a ThreeDSecureInterface before it has been mounted.'),
      );
      return undefined;
    }

    this.iframe.style.height = `${height}px`;
    this.iframe.style.width = `${width}px`;

    return undefined;
  }

  async start(selector) {
    import('./ThreeDSecureUIMounter').then(({ default: threeDSecureUIMounter }) => {
      threeDSecureUIMounter.start.bind(this)(selector);
    });
  }

  unmount() {
    if (this.isMounted()) {
      this.iframe.remove();
      this.iframe = null;

      const singleton = ThreeDSecureMountedUISingleton.create();
      singleton.unregister();
    } else {
      // eslint-disable-next-line no-console
      console.warn('Gateway.js: Frame has not yet been mounted and cannot be unmounted.');
      this.iframe = null;
    }
  }

  isMounted() {
    return this.iframe instanceof window.HTMLIFrameElement;
  }
}
