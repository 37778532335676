import axios from 'axios';
import ErrorFormatter from 'gatewayjs-common/src/ErrorFormatter';

export default class GatewayRepository {
  // eslint-disable-next-line no-unused-vars
  static async fetchConfiguration(publicKey, cartCorrelationId) {
    let extraParams = '';
    if (cartCorrelationId) {
      extraParams = `?cartCorrelationId=${cartCorrelationId}`;
    }
    try {
      const response = await axios.get(
        GatewayRepository.getInitializationEndpointUrl().concat(extraParams),
        {
          headers: {
            Authorization: publicKey,
          },
        },
      );
      return response.data;
    } catch (error) {
      const errorObject = new Error(error.response.data.message);
      throw ErrorFormatter.formatError(errorObject, error.response.data.refId);
    }
  }

  static getInitializationEndpointUrl() {
    const currentScript = document.querySelector('script[src*="js/v1/Gateway.js"]');

    if (!(currentScript instanceof window.HTMLScriptElement)) {
      throw new Error('Could not determine initialization endpoint');
    }

    const scriptUrl = currentScript.getAttribute('src');
    let path = scriptUrl.split('/');
    path.pop();
    path.pop();
    path.pop();
    path = path.join('/');

    const url = new URL(`${path}/gatewayjs/v1/init.php`);
    return url.toString();
  }
}
