import axios from 'axios';
import Eventable from 'gatewayjs-common/src/Eventable';
import createKountSession from './createKountSession';
import getKountConfig from './getKountConfig';

const supportedEvents = ['error', '_debug'];

export default class Kount extends Eventable {
  constructor(kountPromise) {
    super();
    this.kountPromise = kountPromise;
  }

  async createSession() {
    const {
      data: { sessionIdUrl, ddcUrl },
    } = await this.kountPromise;
    const kountConfig = await getKountConfig(ddcUrl);
    const response = await axios.get(sessionIdUrl);
    if (response.status !== 200) {
      // eslint-disable-next-line no-console
      console.error('Unable to load session ID');
    }
    return createKountSession(kountConfig, response.data.sessionId);
  }

  on(eventName, callback) {
    this.throwErrorIfInvalidEventName(eventName, supportedEvents, 'Kount');
    super.on(eventName, callback);
  }

  static create(options) {
    const service = new Kount(options.kountPromise, options.publicKey);

    service.on('error', event => {
      // eslint-disable-next-line no-console
      console.error(event.error);
    });

    service.on('_debug', event => {
      // eslint-disable-next-line no-console
      console.debug(event.message);
    });

    return service;
  }
}
