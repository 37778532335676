export default {
  AED: 'United Arab Emirates Dirham',
  AFN: 'Afghani',
  ALL: 'Lek',
  AMD: 'Armenian Dram',
  ANG: 'Netherlands Antillean Guilder',
  AOA: 'Kwanza',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  AWG: 'Aruban Guilder',
  AZN: 'Azerbaijanian Manat',
  BAM: 'Convertible Marks',
  BBD: 'Barbados Dollar',
  BDT: 'Bangladeshi Taka',
  BGN: 'Bulgarian Lev',
  BHD: 'Bahraini Dinar',
  BIF: 'Burundian Franc',
  BMD: 'Bermudian Dollar',
  BND: 'Brunei Dollar',
  BOB: 'Boliviano',
  BOV: 'Bolivian Mvdol',
  BRL: 'Brazilian Real',
  BSD: 'Bahamian Dollar',
  BTN: 'Ngultrum',
  BWP: 'Pula',
  BYR: 'Belarussian Ruble',
  BYN: 'Belarussian Ruble',
  BZD: 'Belize Dollar',
  CAD: 'Canadian Dollar',
  CDF: 'Franc Congolais',
  CHF: 'Swiss Franc',
  CLP: 'Chilean Peso',
  CNY: 'Yuan Renminbi',
  COP: 'Colombian Peso',
  CRC: 'Costa Rican Colon',
  CUC: 'Peso Convertible',
  CUP: 'Cuban Peso',
  CVE: 'Cape Verde Escudo',

  CZK: 'Czech Koruna',
  DJF: 'Djibouti Franc',
  DKK: 'Danish Krone',
  DOP: 'Dominican Peso',
  DZD: 'Algerian Dinar',
  EGP: 'Egyptian Pound',
  ERN: 'Nakfa',
  ETB: 'Ethiopian Birr',
  EUR: 'Euro',
  FJD: 'Fiji Dollar',
  FKP: 'Falkland Islands Pound',
  GBP: 'Pound Sterling',
  GEL: 'Lari',
  GHS: 'Cedi',
  GIP: 'Gibraltar pound',
  GMD: 'Dalasi',
  GNF: 'Guinea Franc',
  GTQ: 'Quetzal',
  GYD: 'Guyana Dollar',
  HKD: 'Hong Kong Dollar',
  HNL: 'Honduran Lempira',
  HTG: 'Haiti Gourde',
  HUF: 'Forint',
  IDR: 'Rupiah',
  ILS: 'New Israeli Shekel',
  INR: 'Indian Rupee',
  IQD: 'Iraqi Dinar',
  IRR: 'Iranian Rial',
  ISK: 'Iceland Krona',
  JMD: 'Jamaican Dollar',
  JOD: 'Jordanian Dinar',
  JPY: 'Japanese Yen',
  KES: 'Kenyan Shilling',
  KGS: 'Som',
  KHR: 'Riel',
  KMF: 'Comoro Franc',
  KPW: 'North Korean Won',
  KRW: 'South Korean Won',
  KWD: 'Kuwaiti Dinar',
  KYD: 'Cayman Islands Dollar',
  KZT: 'Tenge',
  LAK: 'Kip',
  LBP: 'Lebanese Pound',
  LKR: 'Sri Lanka Rupee',
  LRD: 'Liberian Dollar',
  LSL: 'Loti',
  LYD: 'Libyan Dinar',
  MAD: 'Moroccan Dirham',
  MDL: 'Moldovan Leu',
  MGA: 'Malagasy Ariary',
  MKD: 'Denar',
  MMK: 'Kyat',
  MNT: 'Tugrik',
  MOP: 'Pataca',
  MRU: 'Ouguiya',
  MUR: 'Mauritius Rupee',
  MVR: 'Rufiyaa',
  MWK: 'Kwacha',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  MZN: 'Metical',
  NAD: 'Namibian Dollar',
  NGN: 'Naira',
  NIO: 'Cordoba Oro',
  NOK: 'Norwegian Krone',
  NPR: 'Nepalese Rupee',
  NZD: 'New Zealand Dollar',
  OMR: 'Rial Omani',
  PAB: 'Balboa',
  PEN: 'Nuevo Sol',
  PGK: 'Kina',
  PHP: 'Philippine Peso',
  PKR: 'Pakistan Rupee',
  PLN: 'Zloty',
  PYG: 'Guarani',
  QAR: 'Qatari Rial',
  RON: 'Romanian New Leu',
  RSD: 'Serbian Dinar',
  RUB: 'Russian Ruble',
  RWF: 'Rwanda Franc',
  SAR: 'Saudi Riyal',
  SBD: 'Solomon Islands Dollar',
  SCR: 'Seychelles Rupee',
  SDG: 'Sudanese Pound',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  SHP: 'Saint Helena Pound',
  SLL: 'Leone',
  SOS: 'Somali Shilling',
  SRD: 'Surinam Dollar',
  STD: 'Dobra',
  SYP: 'Syrian Pound',
  SZL: 'Lilangeni',
  THB: 'Baht',
  TJS: 'Somoni',
  TMT: 'Manat',
  TND: 'Tunisian Dinar',
  TOP: "Pa'anga",
  TRY: 'New Turkish Lira',
  TTD: 'Trinidad and Tobago Dollar',
  TWD: 'New Taiwan Dollar',
  TZS: 'Tanzanian Shilling',
  UAH: 'Hryvnia',
  UGX: 'Uganda Shilling',
  USD: 'US Dollar',
  UYU: 'Peso Uruguayo',
  UZS: 'Uzbekistan Som',
  VEF: 'Bolivar Fuerte',
  VES: 'Bolivar Soberano',
  VND: 'Vietnamese dong',
  VUV: 'Vatu',
  WST: 'Samoan Tala',
  XAF: 'CFA Franc BEAC',
  XCD: 'East Caribbean Dollar',
  XCG: 'Caribbean Guilder',
  XOF: 'CFA Franc BCEAO',
  XPF: 'CFP Franc',
  XXX: 'No Currency',
  YER: 'Yemeni Rial',
  ZAR: 'South African Rand',
  ZMK: 'Kwacha',
  ZMW: 'New Kwacha',
  ZWD: 'Zimbabwe Dollar',
  ZWL: 'Zimbabwe Dollar',
};
