import ErrorFormatter from './ErrorFormatter';

class Eventable {
  constructor() {
    this.callbacks = [];
  }

  on(eventName, func) {
    if (!(func instanceof Function)) {
      // eslint-disable-next-line no-console
      console.error('second argument to `on` must be a function');
      return null;
    }

    this.callbacks.push({
      event: eventName,
      function: func,
    });

    return undefined;
  }

  removeListener(eventName, func) {
    const index = this.callbacks.findIndex(callback => callback.function === func);
    this.callbacks.splice(index, 1);

    return undefined;
  }

  invokeCallbacks(eventName, payload) {
    const callbacksToExecute = this.callbacks.filter(callback => callback.event === eventName);
    callbacksToExecute.forEach(callback => callback.function(payload));
  }

  static filterOutPrivateEvents(supportedEvents) {
    return supportedEvents.filter(event => event.substr(0, 1) !== '_');
  }

  /**
   * @param eventName
   * @param supportedEvents
   * @param className this is passed as a string instead of referencing this.constructor.name because minification
   * obfuscates the intended name
   */
  throwErrorIfInvalidEventName(eventName, supportedEvents, className) {
    const publicEvents = Eventable.filterOutPrivateEvents(supportedEvents);

    if (!supportedEvents.includes(eventName)) {
      const error = new Error(
        `Invalid event ${eventName}. ${className} supports the following events: ${publicEvents.join(
          ', ',
        )}`,
      );

      if (supportedEvents.includes('error')) {
        this.invokeCallbacks('error', ErrorFormatter.formatError(error, null));
      } else {
        throw error;
      }
    }
  }
}

export default Eventable;
