import Eventable from 'gatewayjs-common/src/Eventable';
import ErrorFormatter from 'gatewayjs-common/src/ErrorFormatter';
import ThreeDSecureUI from './ThreeDSecureUI/ThreeDSecureUI';
import UUID from '../Model/UUID';
import SilentFailure from '../Model/SilentFailure';
import CurrencyOptions from './CurrencyOptions';

const supportedEvents = ['error', '_debug'];

export default class ThreeDSecure extends Eventable {
  constructor(threeDSPromise, publicKey, cartCorrelationId) {
    super();
    this.threeDSPromise = threeDSPromise;
    this.threeDSPromise
      .then(threeDSData => {
        // This failure indicates that 3DSecure isn't active, which should be handled by Gateway.
        SilentFailure.throwSilentFailureIfUndefined(threeDSData);

        const gwroot = threeDSData?.data?.gwroot;
        this._mountListener(gwroot);
      })
      .catch(error => {
        if (!(error instanceof SilentFailure)) {
          this.invokeCallbacks('error', error);
        }
      });

    this.publicKey = publicKey;
    this.cartCorrelationId = cartCorrelationId;
    this.frames = [];
  }

  on(eventName, callback) {
    this.throwErrorIfInvalidEventName(eventName, supportedEvents, 'ThreeDSecure');
    super.on(eventName, callback);
  }

  static create(options) {
    const service = new ThreeDSecure(
      options.threeDSPromise,
      options.publicKey,
      options.cartCorrelationId,
    );

    service.on('error', event => {
      // eslint-disable-next-line no-console
      console.error(event.error);
    });

    service.on('_debug', event => {
      // eslint-disable-next-line no-console
      console.debug(event.message);
    });

    return service;
  }

  _mountListener(gwroot) {
    const gwrootUrl = new URL(gwroot);
    const gwrootOrigin = gwrootUrl.origin;

    window.addEventListener('message', event => {
      if (event.origin !== gwrootOrigin) {
        this.invokeCallbacks('_debug', {
          message: `Ignored message from origin: ${event.origin}`,
        });
        return;
      }

      if (event.data.service !== 'ThreeDS') {
        const serviceName =
          event?.data?.service !== undefined ? event?.data?.service : 'non-Gateway.js service';

        this.invokeCallbacks('_debug', {
          message: `Ignored a message from another service: ${serviceName}`,
        });
        return;
      }

      const frame = this._getFrame(event.data.frameId);

      if (frame === null) {
        this.invokeCallbacks('_debug', {
          message: `Could not locate a matching frame to raise an event`,
        });
        return;
      }

      frame.invokeCallbacks(event.data.action, event.data.data);
    });
  }

  static _wasCustomerVaultProvided(options) {
    return Object.keys(options).filter(parameter => parameter === 'customerVaultId').length > 0;
  }

  _isValid(options) {
    if (!(options.currency in CurrencyOptions)) {
      const error = new Error(`Invalid Currency`);
      this.invokeCallbacks('error', ErrorFormatter.formatError(error, null));
      return false;
    }

    const customerVaultRequiredParameters = ['currency', 'amount'];

    const nonCustomerVaultRequiredParameters = [
      ...customerVaultRequiredParameters,
      'firstName',
      'lastName',
    ];

    const allowedParameters = [
      ...nonCustomerVaultRequiredParameters,
      'email',
      'city',
      'address1',
      'postalCode',
      'country',
      'phone',
      'customerVaultId',
      'cardNumber',
      'cardExpMonth',
      'cardExpYear',
      'paymentToken',
      'address2',
      'address3',
      'state',
      'shippingCity',
      'shippingAddress1',
      'shippingAddress2',
      'shippingAddress3',
      'shippingCountry',
      'shippingFirstName',
      'shippingLastName',
      'shippingPostalCode',
      'shippingState',
      'processor',
      'challengeIndicator',
      'browserJavaEnabled',
      'browserHeader',
      'browserLanguage',
      'browserColorDepth',
      'browserScreenHeight',
      'browserScreenWidth',
      'browserTimeZone',
      'userAgent',
      'ipAddress',
      'deviceChannel',
      'browserJavascriptEnabled',
    ];

    const extraParams = Object.keys(options).filter(
      parameter => !allowedParameters.includes(parameter),
    );

    if (extraParams.length > 0) {
      const error = new Error(`Unknown options: ${extraParams.join(', ')}`);
      this.invokeCallbacks('error', ErrorFormatter.formatError(error, null));
      return false;
    }

    let requiredParameters;
    if (ThreeDSecure._wasCustomerVaultProvided(options)) {
      requiredParameters = customerVaultRequiredParameters;
    } else {
      requiredParameters = nonCustomerVaultRequiredParameters;
    }

    const emptyRequiredParameters = requiredParameters.filter(
      parameter => typeof options[parameter] === 'undefined' || options[parameter].length === 0,
    );

    if (emptyRequiredParameters.length > 0) {
      const error = new Error(
        `The following options must be provided: ${emptyRequiredParameters.join(', ')}`,
      );
      this.invokeCallbacks('error', ErrorFormatter.formatError(error, null));
      return false;
    }

    return true;
  }

  _getFrame(id) {
    const matchingFrames = this.frames.filter(frame => frame.id === id);

    if (matchingFrames.length > 0) {
      return matchingFrames.pop().frame;
    }

    return null;
  }

  _createUniqueFrameId() {
    let id;

    do {
      id = UUID.generate();
    } while (this._getFrame(id) !== null);

    return id;
  }

  _registerFrame(id, frame) {
    this.frames.push({
      id,
      frame,
    });
  }

  createUI(options) {
    if (!this._isValid(options)) {
      return null;
    }
    const id = this._createUniqueFrameId();

    const frame = ThreeDSecureUI.create({
      ...options,
      threeDSPromise: this.threeDSPromise,
      publicKey: this.publicKey,
      cartCorrelationId: this.cartCorrelationId,
      id,
    });

    this._registerFrame(id, frame);

    return frame;
  }
}
