import Eventable from 'gatewayjs-common/src/Eventable';
import GatewayRepository from './GatewayRepository';
import ThreeDSecure from './ThreeDS/ThreeDSecure';
import Kount from './Kount/Kount';
import SilentFailure from './Model/SilentFailure';
import ConfigParser from './Model/ConfigParser';

const supportedEvents = ['error'];

export default class Gateway extends Eventable {
  constructor(publicKey, cartCorrelationId) {
    super();
    this.publicKey = publicKey;
    this.cartCorrelationId = cartCorrelationId;
    this.configPromise = GatewayRepository.fetchConfiguration(publicKey, cartCorrelationId).catch(
      error => {
        this.invokeCallbacks('error', error);
      },
    );
  }

  on(eventName, callback) {
    this.throwErrorIfInvalidEventName(eventName, supportedEvents, 'Gateway');
    super.on(eventName, callback);
  }

  getThreeDSService() {
    // eslint-disable-next-line no-console
    console.warn('Deprecated: getThreeDSService is deprecated. Please use get3DSecure instead');
    return this.get3DSecure();
  }

  get3DSecure() {
    const threeDSPromise = ConfigParser.getServiceConfigurationPromise(
      this.configPromise,
      '3DSecure',
    ).catch(error => {
      if (!(error instanceof SilentFailure)) {
        this.invokeCallbacks('error', error);
      }
    });

    return ThreeDSecure.create({
      threeDSPromise,
      publicKey: this.publicKey,
      cartCorrelationId: this.cartCorrelationId,
    });
  }

  getKount() {
    const kountPromise = ConfigParser.getServiceConfigurationPromise(
      this.configPromise,
      'Kount',
    ).catch(error => {
      if (!(error instanceof SilentFailure)) {
        this.invokeCallbacks('error', error);
      }
    });

    return Kount.create({
      kountPromise,
    });
  }

  static create(publicKey, cartCorrelationId) {
    const gateway = new Gateway(publicKey, cartCorrelationId);

    gateway.on('error', e => {
      // eslint-disable-next-line no-console
      console.error(e.error);
    });

    return gateway;
  }
}
