// Gateway.js makes extensive use of Promises to lookup information from the platform. Because Gateway.js
// needs these values all over the place, we use Promises all over the place. If a high level Promise fails,
// "undefined" gets used in the proceeding Promises, resulting in many error events being raised. This
// class is an attempt to silence the extra errors. When a promise gets "undefined" for a value, we throw this
// and when a .catch sees this, it knows it can skip the error event.
//
// An alternative to this solution might be to look into a library that allows cancelling Promises, or using something
// like Observables (which also involves a library).
export default class SilentFailure extends Error {
  static throwSilentFailureIfUndefined(undefinedOrNot) {
    if (typeof undefinedOrNot === 'undefined') {
      throw new SilentFailure();
    }
  }
}
