export default function getKountConfig(ddcUrl) {
  return new Promise(resolve => {
    const kountConfig = {
      clientID: '100165',
      isSinglePageApp: false,
    };
    kountConfig.hostname = ddcUrl;
    resolve(kountConfig);
  });
}
