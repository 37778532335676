import '@kount/kount-web-client-sdk';

/* eslint no-param-reassign: ["error", {"props": false}] */
export default function createKountSession(kountConfig, sessionId) {
  return new Promise((resolve, reject) => {
    try {
      window.kountSDK(kountConfig, sessionId);
      return resolve(sessionId);
    } catch (e) {
      return reject(e.message);
    }
  });
}
