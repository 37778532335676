export default class ErrorFormatter {
  static formatError(error, refId, type = 'integrationError') {
    return {
      refId,
      message: error.message,
      type,
      error,
    };
  }
}
